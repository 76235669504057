import React from "react";
import CustomerComp from "../../components/CustomerComp";

function CustomerOutstation() {
  return (
    <>
      <CustomerComp item={(item) => item.workinglocation == "Out-Station"} />;
    </>
  );
}

export default CustomerOutstation;
