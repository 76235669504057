


import React, { useEffect, useState } from "react";
import adminLayout from "../hoc/adminLayout";
import axios from "axios";
import { URL } from "../Url";
import { useNavigate } from "react-router-dom";
import payment from '../assets/images/payment.png'
import babysitter from '../assets/images/babysitter.png'
import rating from '../assets/images/rating.png'
import ayawork from '../assets/images/aayawork.png'
import paid from '../assets/images/paid.png'
import advanceicon from '../assets/images/advance.png'
import pendingicon from '../assets/images/pending.png'
import salary from '../assets/images/salary.png'
import profitimg from '../assets/images/profit.png'
import localimg from '../assets/images/local.png'
import out from '../assets/images/outstation.png'




const DashboardPage = () => {

  const [aya, setAya] = useState([])

  const [customer, setCustomer] = useState([])
  const [assign, setAssign] = useState(0)
  const [local, setLocal] = useState("")
  const [Outstation, setOutstation] = useState("")
  const [ayalocal, setayaLocal] = useState("")
  const [ayaOutstation, setayaOutstation] = useState("")
  const [pending, setPending] = useState([])
  const [advance, setAdvance] = useState([])
  const [notpending, setNotPending] = useState([])
  const [search, setSearch] = useState(false)
  // const [not, setNot] = useState([])
  const [totalCustomerbill, setTotalCustomerBill] = useState([])
  const [totalRec, setTotalRec] = useState([])
  const [pendingAmount, setPenidngAmount] = useState([])
  const [ayaEarning, setAyaEarning] = useState([])
  const [securemoney, setSecureMoney] = useState([])
  const [CustomerBill, setCustomerBill] = useState(0);
  const [ayaPayment, setAayaPayment] = useState(0)
  const [profit, setProfit] = useState(0)
  const [amountRec, setAmountRec] = useState(0)
  const [ayaGeneratedBill, setAyaGeneratedBill] = useState('0')
  const [customerNotAssigned, setCustomerNotAssigned] = useState(0);
  const [securityPaid, setSecurityPaid] = useState(0);
  const [ayaPaid, setAyaPaid] = useState(0);
  const [billGenerated, setBillGenerated] = useState(0);
  const [customerdata, setCustomerdata] = useState([])

  const navigate = useNavigate();





  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalReceived, setTotalReceived] = useState(0);
  const [ayaBill, setAyaBill] = useState(0);
  const [adminProfit, setAdminProfit] = useState(0);


  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };











  const AyaApi = () => {

    axios
      .get(`${URL}/ayareg`)
      .then((res) => {
        setAya(res.data);
        console.log(aya)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);

      });
  };
  const CustomerApi = () => {

    axios
      .get(`${URL}/customerreg`)
      .then((res) => {
        setCustomer(res.data);
        console.log("cucucuuc", res.data)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);

      });
  };



  const compareDate = (billDate) => {
    const todayDate = new Date();
    // console.log('today date format',todayDate)
    const replaceDateParts = billDate.split('-');
    const compareDate = new Date(replaceDateParts[0], replaceDateParts[1] - 1, replaceDateParts[2], 0, 0, 0);
    // console.log('billDate format',compareDate)

    return todayDate <= compareDate;
  }

  const compareFromDate = (billDate) => {
    const todayDate = new Date();
    // console.log('today date format',todayDate)
    const replaceDateParts = billDate.split('-');
    const compareDate = new Date(replaceDateParts[0], replaceDateParts[1] - 1, replaceDateParts[2], 0, 0, 0);
    // console.log('billDate format',compareDate)

    return todayDate <= compareDate;
  }




  const get_diff_days = (billDate) => {
    // Get the current date
    const todayDate = new Date();
    console.log('billdate initial format', billDate);
    // Split the billDate into day, month, and year parts
    const replaceDateParts = billDate.split('-');
    const billDay = parseInt(replaceDateParts[0]);
    const billMonth = parseInt(replaceDateParts[1]) - 1; // JavaScript months are 0-indexed
    const billYear = parseInt(replaceDateParts[2]);

    // Create a Date object for the billDate
    const currentDate = new Date(billYear, billMonth, billDay);

    // Calculate the difference in days
    const timeDifference = todayDate - currentDate; // Difference in milliseconds
    const diffDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    console.log('aaj ka date', todayDate);
    console.log('billdate ka date', currentDate);

    console.log('differnce of days', diffDays)
    return diffDays;
  };





  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const response = await axios.get(`${URL}/customerreg/`);
        const customerData = response.data.data;

        // Calculate total customer bill
        let totalBill = 0;
        let totalRecieved = 0;

        customerData.forEach((customer) => {
          customer.assignedAyaDetails.forEach((payment) => {
            let calc = 0, diffDays = 0;
            if (payment.assignedAyaRate) {
              if (payment.assignedAyaToDate) {
                const date1 = new Date(payment.assignedAyaFromDate);
                const date2 = new Date(payment.assignedAyaToDate);
                const diffTime = Math.abs(date2 - date1);
                diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                // console.log("differnece of date of from and to",diffDays);
              } else {
                const date1 = new Date(payment.assignedAyaFromDate);
                const date2 = new Date();
                const diffTime = Math.abs(date2 - date1);
                diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                // console.log('differnece of today and from date',diffDays);
              }
            }
            calc = diffDays * (payment.assignedAyaRate);
            // console.log('yeh calculated money',calc)
            totalBill += parseInt(calc);
          });
        });
        customerData.forEach((customer) => {
          customer.customerGeneratedInvoice.forEach((payment) => {
            totalRecieved += parseInt(payment.generatedBill);
          });
        });

        console.log("customer data:", customerData);
        setCustomerBill(totalBill);
        setAmountRec(totalRecieved)


        let cnt = 0;
        customerData.forEach((customer) => {
          if (customer.assignedAyaDetails.length <= 0) {
            cnt++;
          } else {
            const reverseData = customer.assignedAyaDetails;
            let len = reverseData.length

            if (reverseData.length > 0 && reverseData[len - 1].assignedAyaFromDate) {
              if (compareDate(reverseData[len - 1].assignedAyaFromDate)) {
                if (reverseData[len - 1].assignedAyaToDate && !compareDate(reverseData[len - 1].assignedAyaToDate)) {
                  cnt++;
                  console.log('to date kam hai')

                } else if (reverseData[len - 1].assignedAyaToDate) {
                  cnt++;
                  console.log('to date khaali hi hai')

                }
                // console.log('to date  khaali hi hai')
              }
            }
          }
        });
        // console.log('chala yan hi')

        setCustomerNotAssigned(cnt);

        let billGenerated = 0;




        customerData.forEach((customer) => {
          // console.log('single customer',customer)
          if (customer.customerGeneratedInvoice.length <= 0) {
            let length = customer.assignedAyaDetails.length;
            if (length > 0 && get_diff_days(customer.assignedAyaDetails[length - 1].assignedAyaToDate) > 30) {
              billGenerated++;
            }
          } else {
            const reverseData = customer.customerGeneratedInvoice;
            let len = reverseData.length

            if (reverseData.length > 0 && reverseData[len - 1].generatedToDate) {
              // console.log('dekhe to kaise bna hai',get_diff_days(reverseData[0].generatedToDate));
              if (get_diff_days(reverseData[len - 1].generatedToDate) > 30) {

                billGenerated++;
              } else {
                // console.log('inner customer is assigned')
              }
            } else {
              billGenerated++;

              // console.log('outer customer is assigned')
            }
          }
        });

        setBillGenerated(billGenerated);


        const secureAmt = customerData.reduce((sum, item) => sum + parseInt(item.securityAmount), 0);
        console.log("seekek", secureAmt)
        setSecureMoney(secureAmt)

        let securityPaid = 0;
        customerData.forEach((customer) => {
          if (customer.securityAmount < 2000) securityPaid++;
        })

        setSecurityPaid(securityPaid);


      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    fetchCustomerData();
  }, []);












  useEffect(() => {
    const fetchaayaPayment = async () => {
      try {
        const response = await axios.get(`${URL}/ayareg/`);
        const customerData = response.data.data;

        // Calculate generated aya bill
        let totalBill = 0;
        let AdminProfit = 0, ayaGeneratedBill = 0;

        customerData.forEach((customer) => {
          customer.assignedCustomerDetails.forEach((payment) => {
            let calc = 0, diffDays = 0;
            if (payment.assignedCustomerRate) {
              if (payment.assignedCustomerToDate) {
                const date1 = new Date(payment.assignedCustomerFromDate);
                const date2 = new Date(payment.assignedCustomerToDate);
                const diffTime = Math.abs(date2 - date1);
                diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                // console.log("differnece of date of from and to",diffDays);
              } else {
                const date1 = new Date(payment.assignedCustomerFromDate);
                const date2 = new Date();
                const diffTime = Math.abs(date2 - date1);
                diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                // console.log('differnece of today and from date',diffDays);
              }
            }
            calc = diffDays * (payment.assignedCustomerRate);
            // console.log('yeh calculated money',calc)
            totalBill += parseInt(calc);
          });
        });
        setAyaGeneratedBill(totalBill);


        customerData.forEach((customer) => {
          customer.ayaGeneratedInvoice.forEach((payment) => {
            // console.log('aya ka geneerated bill',payment.generatedBill)
            ayaGeneratedBill += parseInt(payment.generatedBill);
          });
        });

        setAayaPayment(ayaGeneratedBill);

        let cnt = 0;
        customerData.forEach((customer) => {
          if (customer.assignedCustomerDetails.length <= 0) {
            cnt++;
          } else {
            const reverseData = customer.assignedCustomerDetails;
            let len = reverseData.length


            if (reverseData.length > 0 && reverseData[len - 1].assignedCustomerFromDate) {
              if (compareFromDate(reverseData[len - 1].assignedCustomerFromDate)) {
                cnt++;
              } else if (reverseData[len - 1].assignedCustomerToDate && !compareDate(reverseData[len - 1].assignedCustomerToDate)) {
                cnt++;
              }
            }
          }
        });


        setAssign(cnt);

        let ayaPaid = 0;
        customerData.forEach((customer) => {
          // console.log('single customer',customer)
          if (customer.ayaGeneratedInvoice.length <= 0 && customer.assignedCustomerDetails.length > 0) {
            let length = customer.assignedCustomerDetails.length;
            if (get_diff_days(customer.assignedCustomerDetails[length - 1].assignedCustomerFromDate) > 30) {
              ayaPaid++;
            }
          } else {
            const reverseData = customer.ayaGeneratedInvoice;
            let len = reverseData.length
            if (reverseData.length > 0 && reverseData[len - 1].generatedToDate) {
              // console.log('dekhe to kaise bna hai',get_diff_days(reverseData[0].generatedToDate));
              if (get_diff_days(reverseData[len - 1].generatedToDate) > 30) {

                ayaPaid++;
              } else {
                // console.log('inner customer is assigned')
              }
            } else {
              ayaPaid++;
              // console.log('outer customer is assigned')
            }
          }
        });

        setAyaPaid(ayaPaid);
        // console.log("totalAaauauuapayment:", ayaGeneratedBill);
        // setProfit(AdminProfit)
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    fetchaayaPayment();
  }, []);








  const pendingPayment = () => {
    axios.get(`${URL}/customerreg/`).then((res) => {
      const filteredCustomer = res.data.data.filter((item) => item.status === "Pending");
      setPending(filteredCustomer.length);
      console.log(pending)
    });
  };

  const worklocation = () => {
    axios.get(`${URL}/customerreg/`).then((res) => {
      const filteroutstation = res.data.data.filter((item) => item.workinglocation === "Out-Station");
      const localfilter = res.data.data.filter((item) => item.workinglocation === "Local");
      setOutstation(filteroutstation.length);
      setLocal(localfilter.length);
      console.log(pending)
    });
  }


  const ayaworklocation = () => {
    axios.get(`${URL}/ayareg/`).then((res) => {
      const filteroutstation = res.data.data.filter((item) => item.workinglocation === "Out-Station");
      const localfilter = res.data.data.filter((item) => item.workinglocation === "Local");
      setayaOutstation(filteroutstation.length);
      setayaLocal(localfilter.length);
      console.log(pending)
    });
  }

















  const totalcustomerbill = () => {
    axios.get(`${URL}/customerreg/`).then((res) => {
      const customerData = res.data.data;

      // Calculate total customer bill
      const totalBill = customerData.reduce((sum, item) => sum + parseInt(item.customerbill), 0);

      setTotalCustomerBill(totalBill)
    });
  };


  const totalAmountRec = () => {
    axios.get(`${URL}/customerreg/`).then((res) => {
      const customerData = res.data.data;

      // Calculate total customer bill
      const totalBill = customerData.reduce((sum, item) => sum + parseInt(item.amount_received), 0);

      console.log(totalBill)
      setTotalRec(totalBill)
    });
  };







  const totalayaEarn = () => {
    axios.get(`${URL}/ayareg/`).then((res) => {
      const customerData = res.data.data;

      // Calculate total customer bill

      const totalEarn = customerData.reduce((sum, item) => sum + parseInt(item.ayaearn), 0);
      console.log("ayaearn", totalEarn)
      setAyaEarning(totalEarn)
    });
  };




  const handleNumberNany = () => {
    navigate('/ayalist')
  }

  const handleCustomer = () => {
    navigate('/customerlist')
  }


  const handleCustomerPayment = () => {
    navigate('/totalAmount')
  }




  const handleayaPayment = () => {
    navigate('/ayapayment')
  }





  const checkpaymentList = () => {
    axios.get(`${URL}/customerreg/`).then((res) => {
      const customerData = res.data.data;

      // Calculate total customer bill
      const totalRec = customerData.reduce((sum, item) => sum + parseInt(item.amount_received), 0);
      const customerbill = customerData.reduce((sum, item) => sum + parseInt(item.customerbill), 0);

      const totalbill = totalRec - customerbill

      const pendingAmt = totalRec - customerbill


      // setTotalRec(totalbill)
      console.log("newTotalbill", totalbill)



      if (totalbill > 0) {

        setAdvance(totalbill)

      } else {
        setAdvance(0);
      }

      if (pendingAmt > 0) {

        setPenidngAmount(0)

      } else {
        setPenidngAmount(pendingAmt)
      }
    });
  };

  const handleProfit = () => {
    navigate('/ayapayment')
  }

  const handleNannywork = () => {
    navigate('/totalAvaibleNanny')
  }

  const handleCustomerwork = () => {
    navigate('/totalAvaibleCustomer')
  }

  const handleSecurity = () => {
    navigate('/securityAmount')
  }

  const handleCustomerBill = () => {
    navigate('/customerBill')
  }




  useEffect(() => {
    AyaApi();
    CustomerApi();
    // assignAya();
    pendingPayment()

    totalcustomerbill()
    totalAmountRec()

    totalayaEarn()
    checkpaymentList()
    worklocation()
    ayaworklocation()


  }, [])






  // const handleSearch = () => {
  //   // Perform Axios GET requests with the selected date range
  //   axios.get(`${URL}/customerreg`)
  //     .then((response) => {
  //       // Filter customer data based on the createdAt date within the selected range
  //       const customerData = response.data.data.filter((customer) => {
  //         const createdAtDate = new Date(customer.createdAt);
  //         return createdAtDate >= new Date(startDate) && createdAtDate <= new Date(endDate);
  //       });

  //       // Calculate totalReceived based on the filtered data
  //       let total = 0;
  //       customerData.forEach((customer) => {
  //         customer.ayaGeneratedInvoice.forEach((invoice) => {
  //           total += parseInt(invoice.generatedBill);
  //         });
  //       });
  //       setTotalReceived(total);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });

  //   axios.get(`${URL}/ayareg`)
  //     .then((response) => {
  //       // Calculate ayaGeneratedBill based on the createdAt date within the selected range
  //       const ayaData = response.data.data;
  //       let total = 0;
  //       ayaData.forEach((customer) => {
  //         const filteredInvoices = customer.ayaGeneratedInvoice.filter((invoice) => {
  //           const invoiceCreatedAt = new Date(invoice.createdAt);
  //           return invoiceCreatedAt >= new Date(startDate) && invoiceCreatedAt <= new Date(endDate);
  //         });
  //         filteredInvoices.forEach((invoice) => {
  //           total += parseInt(invoice.generatedBill);
  //         });
  //       });
  //       setAyaBill(total);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };


  const handleSearch = () => {
    setSearch(true)
    // Perform Axios GET requests with the selected date range
    axios.get(`${URL}/customerreg`)
      .then((response) => {
        // Calculate totalReceived based on the filtered data
        let totalReceived = 0;
        response.data.data.forEach((customer) => {
          const filteredInvoices = customer.customerGeneratedInvoice.filter((invoice) => {
            const invoiceCreatedAt = new Date(invoice.createdAt);
            return invoiceCreatedAt >= new Date(startDate) && invoiceCreatedAt <= new Date(endDate);
          });
          filteredInvoices.forEach((invoice) => {
            totalReceived += parseInt(invoice.generatedBill);
          });
        });
        setTotalReceived(totalReceived);
      })
      .catch((error) => {
        console.error(error);
      });

    axios.get(`${URL}/ayareg`)
      .then((response) => {
        // Calculate ayaGeneratedBill based on the response
        const ayaData = response.data.data;
        let ayaGeneratedBill = 0;
        ayaData.forEach((customer) => {
          customer.ayaGeneratedInvoice.forEach((payment) => {
            ayaGeneratedBill += parseInt(payment.generatedBill);
          });
        });
        setAyaBill(ayaGeneratedBill);
      })
      .catch((error) => {
        console.error(error);
      });


  };








  useEffect(() => {
    const profit = totalReceived - ayaBill;
    console.log("prprprpprp", profit)
    setAdminProfit(profit);
  }, [handleSearch, adminProfit])


  // Calculate adminProfit
  // useEffect(() => {
  //   const calculateAdminProfit = () => {

  //   };
  // }, [handleSearch])
  return (
    <>
      <div className="row">

        <div className="col-md-12">
          <div className="row align-items-center my-3">
            <div className="col-md-4">
              <label htmlFor="startDate" className="fw-bold">Start Date:</label>
              <input
                type="date"
                id="startDate"
                className="form-control"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="endDate" className="fw-bold">End Date:</label>
              <input
                className="form-control"
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <button onClick={handleSearch} className="btn bg-primary text-white mt-3">Search</button>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleNumberNany} style={{ cursor: "pointer" }}>
          <div className="card text-white  bg-primary o-hidden h-100">
            <div className="card-body mt-3">

              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-user"></i> */}
                  <img src={babysitter} alt="" className="img-fluid boximg" />
                </div>
                <h4>Aya</h4>
                <h5 className="fw-bold ">{aya.count}</h5>
              </div>
            </div>

          </div>
        </div>


        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleNannywork} style={{ cursor: "pointer" }}>
          <div className="card text-white bg-success  o-hidden h-100">
            <div className="card-body">
              <div className="mr-5 cardbox mt-3">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-briefcase"></i> */}
                  <img src={ayawork} alt="" className="img-fluid boximg" style={{ width: "70px" }} />
                </div>
                <h4>
                  Available Aya
                </h4>
                <h5 className="fw-bold">{assign}</h5>
              </div>
            </div>

          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3" style={{ cursor: "pointer" }}>
          <div className="card text-white  o-hidden h-100" style={{ background: "#008080" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  <img src={payment} className="img-fluid boximg" style={{ width: "90px" }} />
                </div>
                <h4>Aya Bill</h4>
                <h5 className="fw-bold"> {ayaGeneratedBill}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleayaPayment} style={{ cursor: "pointer" }}>
          <div className="card text-white  o-hidden h-100" style={{ background: "#191970" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={salary} alt="" className="img-fluid boximg" />
                </div>
                <h4>Aya Amount not Paid</h4>
                <h5 className="fw-bold">{ayaPaid}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleCustomer} style={{ cursor: "pointer" }}>
          <div className="card text-white o-hidden h-100" style={{ background: "#b86d6d" }}>
            <div className="card-body">

              <div className="mr-5 cardbox mt-3">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-users"></i> */}
                  <img src={rating} alt="" className="img-fluid boximg" style={{ width: "70px" }} />
                </div>

                <h4>Customer</h4>

                <h5 className="fw-bold "> {customer.count}</h5>
              </div>
            </div>

          </div>
        </div>

        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleCustomerwork} style={{ cursor: "pointer" }}>
          <div className="card text-white bg-success  o-hidden h-100">
            <div className="card-body">
              <div className="mr-5 cardbox mt-3">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-briefcase"></i> */}
                  <img src={rating} alt="" className="img-fluid boximg" style={{ width: "70px" }} />
                </div>
                <h4>
                  Customer not assigned
                </h4>
                <h5 className="fw-bold">{customerNotAssigned}</h5>
              </div>
            </div>
            {/* <a className="card-footer text-white clearfix small z-1" href="#">
              <span className="float-left">View Details</span>
              <span className="float-right">
                <i className="fa fa-angle-right"></i>
              </span>
            </a> */}
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleCustomerPayment} style={{ cursor: "pointer" }}>
          <div className="card text-white  o-hidden h-100" style={{ background: "#008080" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  <img src={payment} className="img-fluid boximg" style={{ width: "90px" }} />
                </div>
                <h4>Customer Bill</h4>
                <h5 className="fw-bold"> {CustomerBill}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleCustomerBill} style={{ cursor: "pointer" }}>
          <div className="card text-white  o-hidden h-100" style={{ background: "#2F4F4F" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={pendingicon} alt="" className="img-fluid boximg" />
                </div>
                <h4>Customer Bill not Generated</h4>
                <h5 className="fw-bold">{billGenerated}</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleSecurity} style={{ cursor: "pointer" }}>
          <div className="card text-white  o-hidden h-100" style={{ background: "#6495ED" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={advanceicon} alt="" className="img-fluid boximg" />
                </div>
                <h4> Secuirty Money</h4>
                <h5 className="fw-bold">{securemoney}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3" onClick={() => navigate('/notgivensecurity')} style={{ cursor: "pointer" }}>
          <div className="card text-white  o-hidden h-100" style={{ background: "#6495ED" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={advanceicon} alt="" className="img-fluid boximg" />
                </div>
                <h4>Not Given base Secuirty Money</h4>
                <h5 className="fw-bold">{securityPaid}</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleCustomerPayment} style={{ cursor: "pointer" }}>
          <div className="card text-white  o-hidden h-100" style={{ background: "#2F4F4F" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={pendingicon} alt="" className="img-fluid boximg" />
                </div>
                <h4>Received from Customer</h4>
                <h5 className="fw-bold">{amountRec}</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-6 mb-3" onClick={handleayaPayment} style={{ cursor: "pointer" }}>
          <div className="card text-white  o-hidden h-100" style={{ background: "#191970" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={salary} alt="" className="img-fluid boximg" />
                </div>
                <h4> Total Aya Payment</h4>
                <h5 className="fw-bold">{ayaPayment}</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-6 mb-3" style={{ cursor: "pointer" }}>
          <div className="card text-white  o-hidden h-100" style={{ background: " #7B68EE" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">

                  <img src={profitimg} alt="" className="img-fluid boximg" />
                </div>
                <h4> Total Admin Profit</h4>
                {search ? <h5 className="fw-bold"> {adminProfit}
                </h5> :
                  <h5 className="fw-bold">{amountRec - ayaPayment}
                  </h5>}


                {console.log("amamamamma", amountRec)}
                {console.log("ayyapayment", ayaPayment)}


              </div>
            </div>
          </div>
        </div>


        <div className="col-xl-3 col-sm-6 mb-3" style={{ cursor: "pointer" }} onClick={() => navigate('/localcustomer')}>
          <div className="card text-white  o-hidden h-100" style={{ background: "#191970" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={localimg} alt="" className="img-fluid boximg" />
                </div>
                <h4> Local Customer </h4>
                <h5 className="fw-bold">{local}</h5>
              </div>
            </div>
          </div>
        </div>


        <div className="col-xl-3 col-sm-6 mb-3" style={{ cursor: "pointer" }} onClick={() => navigate('/outstationCustomer')}>
          <div className="card text-white  o-hidden h-100" style={{ background: "rgb(29, 91, 121)" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={out} alt="" className="img-fluid boximg" />
                </div>
                <h4> Outstation Customer </h4>
                <h5 className="fw-bold"> {Outstation}</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-6 mb-3" style={{ cursor: "pointer" }} onClick={() => navigate('/localaya')}>
          <div className="card text-white  o-hidden h-100" style={{ background: "rgb(70, 139, 151)" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={localimg} alt="" className="img-fluid boximg" />
                </div>
                <h4> Local Aya </h4>
                <h5 className="fw-bold">{ayalocal}</h5>
              </div>
            </div>
          </div>
        </div>


        <div className="col-xl-3 col-sm-6 mb-3" style={{ cursor: "pointer" }} onClick={() => navigate('/outstationaya')}>
          <div className="card text-white  o-hidden h-100" style={{ background: "rgb(29, 91, 121)" }}>
            <div className="card-body mt-3">
              <div className="mr-5 cardbox">
                <div className="card-body-icon">
                  {/* <i class="fa-solid fa-hand-holding-dollar"></i> */}
                  <img src={out} alt="" className="img-fluid boximg" />
                </div>
                <h4> Outstation Aya </h4>
                <h5 className="fw-bold"> {ayaOutstation}</h5>
              </div>
            </div>
          </div>
        </div>




        {/* <div>
          <h1>Admin Profit Calculator</h1>
          <div>
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="endDate">End Date:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <button onClick={handleSearch}>Search</button>
          
        </div> */}
        <div className="d-none">
          <p>Total Received: {totalReceived}</p>
          <p>Aya Generated Bill: {ayaBill}</p>
          <p>Admin Profit: {adminProfit}</p>
        </div>


      </div>
    </>
  );
};




export default adminLayout(DashboardPage);


