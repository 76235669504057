import React from "react";

import TotalAvailbleCustomer from "./TotalAvailbleCustomer";
import CustomerComp from "../components/CustomerComp";

function SecurityMoney() {
  return (
    <>
      <CustomerComp item={(item) => item.securityAmount !== "0"} />;
      {/* <TotalAvailbleCustomer /> */}
    </>
  );
}

export default SecurityMoney;
