import React from "react";
import AyaComp from "../../components/AyaComp";

function OutstationAya() {
  return (
    <>
      <AyaComp item={(item) => item.workinglocation == "Out-Station"} />
    </>
  );
}

export default OutstationAya;
