import React from "react";
import CustomerComp from "../../components/CustomerComp";

function CustomerLocal() {
  return (
    <>
      <CustomerComp item={(item) => item.workinglocation == "Local"} />;
    </>
  );
}

export default CustomerLocal;
