import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { URL } from "../Url";
import axios from "axios";
import { Link, Navigate, useNavigate, useNavigation } from "react-router-dom";
import * as XLSX from "xlsx";

const CustomerListContent = (props) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiCategory = () => {
    setLoading(true);
    axios
      .get(`${URL}/customerreg`)
      .then((res) => {
        console.log(res.data.data);

        let filteredData = res.data.data;

        if (props.type) {
          // Filter data based on the workinglocation
          filteredData = res.data.data.filter(
            (item) => item.workinglocation === props.type
          );
        }

        // const data = filteredData.map((item) => {
        //   const assignedAyaDetails = [...item.assignedAyaDetails].reverse();
        //   return { ...item, assignedAyaDetails };
        // });
        setList(filteredData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  // console.log(type)

  const handleRowClick = (params) => {
    const field = params.field;
    console.log("aisa kya hai isme", field);
    if (field === "assignedAyaName" || field === "replaceAyaDetails") {
      return;
      // console.log("bhai tu chaal bhi rha hai ?", params.row.assign);
      // const AyaId = params.row.assign;
      // navigate(`/Ayareg/${AyaId}`);
    } else {
      navigate(`/customerreg/${params.row._id}`);
    }
  };

  const checkDateDifference = (data) => {
    if (data && data.length > 0) {
      // const reverseData = data[0].assignedAyaDetails.reverse();
      const fromDate = new Date(data[0].assignedAyaFromDate);
      const toDate = new Date(data[0].assignedAyaToDate);
      const todayDate = new Date();
      // console.log("from date let's see",fromDate);
      // console.log("toDate date let's see",toDate)
      // console.log("today date let's see",todayDate)

      if (todayDate >= fromDate && todayDate <= toDate) {
        // Today's date is between fromDate and toDate
        return true;
      } else if (toDate == "Invalid Date" && todayDate >= fromDate) {
        return true;
      } else {
        return false;
      }
    }
    return false; // Handle the case when data is missing or empty
  };

  const replacedDateDifference = (data) => {
    if (data && data.length > 0) {
      // const reverseData = data[0].assignedAyaDetails.reverse();
      const fromDate = new Date(data[data.length - 1].replaceAyaFromDate);
      const toDate = new Date(data[data.length - 1].replaceAyaToDate);
      const todayDate = new Date();
      // console.log("from date let's see",fromDate);
      // console.log("toDate date let's see",toDate)
      // console.log("today date let's see",todayDate)

      if (todayDate >= fromDate && todayDate <= toDate) {
        // Today's date is between fromDate and toDate
        return true;
      } else if (toDate == "Invalid Date" && todayDate >= fromDate) {
        return true;
      } else {
        return false;
      }
    }
    return false; // Handle the case when data is missing or empty
  };

  useEffect(() => {
    apiCategory();
  }, []);

  useEffect(() => {
    apiCategory();
  }, [props.type]);

  // console.log(list.file);

  //download Excel
  // const handleDownloadExcel = () => {
  // const worksheet = XLSX.utils.json_to_sheet(list);
  // const workbook = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(workbook, worksheet, "Customer Data");
  // XLSX.writeFile(workbook, "Customer_data.xlsx");
  // };

  const getRowId = (row) => row._id;
  const columns = [
    {
      field: "sr.no",
      headerName: "SR.NO",
      width: 100,
      editable: false,
      renderCell: (params) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row._id) + 1,

    },
    {
      field: "idCardNumber",
      headerName: "Id Card",
      width: 100,


    },
    {
      field: "idcardimage",
      headerName: "Id Card Image",
      width: 200,
      renderCell: (params) => {

        return (
          <img
            src={`${URL}/${params.row.idCardImage}`}
            className="tableimgmui"
            style={{ height: "55px", width: "55px" }}
          ></img>
        );
      },
    },
    {
      field: "file",
      headerName: "IMAGE",
      width: 80,
      renderCell: (params) => {
        const { value } = params;
        return (
          <img
            src={`${URL}/${value}`}
            className="tableimgmui"
            style={{ height: "55px", width: "55px" }}
          ></img>
        );
      },
    },
    {
      field: "customerCode",
      headerName: "C CODE",
      width: 85,
      type: "string",
      editable: true,
    },

    {
      field: "name",
      headerName: "CUSTOMER NAME",
      type: "string",
      width: 200,
      editable: true,
    },
    // {
    //   field: 'workShift',
    //   headerName: 'SHIFT',
    //   type: 'string',
    //   width: 80,
    //   editable: true,
    // },
    {
      field: "contactNumber",
      headerName: "CONTACT NO.",
      type: "string",
      width: 200,
      editable: true,
    },

    {
      field: "presentAddress",
      headerName: "LOCATION",
      type: "string",
      width: 300,
      editable: true,
    },
    {
      field: "assignedAyaName",
      headerName: "ASSIGNED",
      type: "string",
      width: 200,
      editable: true,
      renderCell: (params) => {
        //  i don't know why reverse data aa rha hai, to isko as it is chod do
        // const data = params.row.assignedAyaDetails;
        // let dataLen = data.length;
        // console.log("bhai tahbi mein kisme kr rha tha", data);
        // // console.log("bhai tahbi mein kisme kr rha tha",checkDateDifference(data));
        // if (dataLen > 0 && checkDateDifference(data)) {
        //   return (
        //     <Link
        //       to={`/ayareg/${data[dataLen - 1].assignedAyaCode}`}
        //       className="btn-success btn text-white"
        //     >
        //       {data[0].assignedAyaName}
        //     </Link>
        //   );
        // } else {
        //   return (
        //     <Link
        //       to={`/customerassign/${params.row._id}`}
        //       className="btn-danger btn text-white"
        //     >
        //       Assign Aya
        //     </Link>
        //   );
        // }
        console.log("dataconstruct", params.row);
        const data = [...params.row.assignedAyaDetails].reverse(); // Create a new reversed array
        const datalength = data.length;
        console.log("Datalengthhtht", datalength);
        console.log("data", data);

        // const replace = data?.replaceAyaDetails.reverse();
        // console.log("replalce", replace);

        const replace = data?.[0]?.replaceAyaDetails.reverse();

        const replacelength = replace?.length;

        console.log("replace", replace);

        console.log("replacelength", replacelength);

        console.log("Replace name", replace?.[0]?.replaceAyaName);
        if (datalength > 0 && checkDateDifference(data)) {
          return (
            <Link
              to={`/ayareg/${data[0].assignedAyaCode}`}
              className="btn-success btn text-white"
            >
              {/* {data[0].assignedAyaName} */}
              {replacelength > 0
                ? replace[0]?.replaceAyaName
                : data[0].assignedAyaName}
            </Link>
          );
        } else {
          return (
            <Link
              to={`/customerassign/${params.row._id}`}
              className="btn-danger btn text-white"
            >
              Assign Aya
            </Link>
          );
        }
      },
    },

    {
      field: "rate",
      headerName: "RATE",
      type: "string",
      width: 200,
      editable: true,
      renderCell: (params) => {
        const data = params.row.assignedAyaDetails;
        let dataLen = data.length;
        if (data && data.length > 0 && data[dataLen - 1].assignedAyaRate) {
          return <>{data[dataLen - 1].assignedAyaRate}</>;
        } else {
          return null; // or render a placeholder text or component
        }
      },
    },
    {
      field: "assignedAyaPurpose",
      headerName: "PURPOSE",
      type: "string",
      width: 200,
      editable: true,
      renderCell: (params) => {
        const data = params.row.assignedAyaDetails;
        let dataLen = data.length;
        if (data && data.length > 0 && data[dataLen - 1].assignedAyaPurpose) {
          return <>{data[dataLen - 1].assignedAyaPurpose}</>;
        } else {
          return null; // or render a placeholder text or component
        }
      },
    },
    {
      field: "assignedAyaShift",
      headerName: "SHIFT",
      type: "string",
      width: 200,
      editable: true,
      renderCell: (params) => {
        const data = params.row.assignedAyaDetails;
        let dataLen = data.length;
        if (data && data.length > 0 && data[dataLen - 1].assignedAyaShift) {
          return <>{data[dataLen - 1].assignedAyaShift}</>;
        } else {
          return null; // or render a placeholder text or component
        }
      },
    },
    {
      field: "replaceAyaDetails",
      headerName: "REPLACED",
      type: "string",
      width: 200,
      editable: true,
      renderCell: (params) => {
        const data = params.row.assignedAyaDetails;
        let dataLen = data.length;
        if (data && data.length > 0 && data[dataLen - 1].replaceAyaDetails) {
          const replaceDetails = data[dataLen - 1].replaceAyaDetails;
          if (replaceDetails) {
            // const replaceDetailsCopy = replaceDetails;
            // let length =

            // console.log('bhai yeh apne aap chal rrha tha', replaceDetailsCopy);
            return replacedDateDifference(replaceDetails) ? (
              <Link
                to={`/customerassign/${params.row._id}`}
                className="btn-warning btn text-black"
              >
                YES
              </Link>
            ) : (
              <Link
                to={`/customerassign/${params.row._id}`}
                className="btn-success btn text-white"
              >
                NO
              </Link>
            );
          }
        }
        return (
          <Link
            to={`/customerassign/${params.row._id}`}
            className="btn-success btn text-white"
          >
            NO
          </Link>
        );
      },
    },
    {
      field: "closingDate",
      headerName: "Closing Date",
      type: "string",
      width: 200,
      editable: true,
      renderCell: (params) => {
        const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
        const cellDate = new Date(params.row.closingDate).toISOString().split('T')[0]; // Convert closingDate to YYYY-MM-DD format

        const isSameDate = cellDate === currentDate;

        return (
          <div
            className={isSameDate ? "bg-danger" : "bg-success"}
            style={{

              color: 'white',
              padding: '5px',
              borderRadius: '4px',
              textAlign: 'center',
              width: '100%',
            }}
          >
            {params.row.closingDate}
          </div>
        );
      },
    },
  ];
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={list}
        columns={columns}
        getRowId={getRowId}
        onCellClick={handleRowClick}
        // onRowClick={handleRowClick}
        pagination={false} // Set pagination to false
        // initialState={{
        //   pagination: {
        //     paginationModel: {
        //       pageSize: 10,
        //     },
        //   },
        // }}
        // pageSizeOptions={[10]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default CustomerListContent;
